function getIframeAtt(iframeString) {
  return iframeString
    .slice(8, iframeString.indexOf('>'))
    .replace(/" /g, '",')
    .split(',')
    .reduce((acc, att) => {
      const key = att.slice(0, att.indexOf('='))
      const value = att.slice(att.indexOf('=') + 1).replace(/"/g, '')

      acc[key] = value
      return acc
    }, {})
}

function getIframeSrc(iframeString) {
  const startScr = iframeString.indexOf('src=') + 4

  return iframeString.slice(startScr, iframeString.indexOf(' ', startScr)).replace(/"/g, '')
}

function convertUrlToObj(urlString) {
  const [url, params] = urlString.split('?')
  const paramsObj = params.split('&').reduce((acc, att) => {
    const [key, value] = att.split('=')

    acc[key] = value
    return acc
  }, {})

  return {
    url,
    ...paramsObj,
  }
}

function convertObjToUrl(urlObj) {
  const url = urlObj.url
  delete urlObj.url
  const queryParamsString = Object.entries(urlObj).reduce((acc, [key, value]) => {
    return `${acc}&${key}=${value}`
  }, '')

  return `${url}?${queryParamsString}`
}

const { videoImagePath } = require('../const')

function updateVideoImageUrl(videoObj) {
  videoObj.thumbnail = videoObj.thumbnail.startsWith('http') ? videoObj.thumbnail : videoImagePath + videoObj.thumbnail

  return videoObj
}

export default {
  methods: {
    getIframeAtt,
    getIframeSrc,
    convertUrlToObj,
    convertObjToUrl,
    updateVideoImageUrl,
  },
}
