<template>
  <div class="main-container">
    <NavBar :src="require('@/assets/images/background.png')" :gotoOffset="offset" @courseAssign="assignDialog = true" />
    <AboutUs @click:courseAssign="assignDialog = true" @click:courseWhatsup="whatsupAssign = true" />
    <Manifest :manifest-list="manifest.manifest" :manifest-header="manifest.header" :manifest-sub="manifest.sub" />
    <AdvicesContainer id="advices-container" class="border-top" bg-image="images/background2.png" />
    <Syllabus id="syllabus" />
    <VideoGallery id="video-gallery" class="border-top" />
    <PostsGallery id="posts-gallery" class="border-top" />
    <Quotes id="quotes" :men-quotes="menQuotes" class="border-top" />

    <Footer bg-color="#FBEEE6" />
    <FormContainer
      :enable-dialog="whatsupAssign"
      bg-color="#FBEEE6"
      :title="whatsUpHeader"
      @click:close="whatsupAssign = false"
      ><WhatsUpForm @assign:sent="whatsupReqSent" />
    </FormContainer>
    <FormContainer :enable-dialog="assignDialog" bg-color="#FBEEE6" :title="assignHeader" @click:close="closeAssign"
      ><CourseAssignForm @assign:sent="assignSent" />
    </FormContainer>

    <Alert
      class="alert-component"
      :enable="alertEnable"
      :message="alertObj.message"
      :type="alertObj.type"
      :timeoutEnable="true"
      @close="alertEnable = false"
    />

    <v-dialog
      v-model="reviewsActive"
      content-class="dialog-content"
      transition="slide-x-transition"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="reviews-btn" color="success" v-bind="attrs" v-on="on">{{ reviewButton }}</v-btn>
      </template>
      <Reviews @click:back="reviewsActive = false" />
    </v-dialog>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar'
import AboutUs from './AboutUs'
import AdvicesContainer from './AdvicesContainer.vue'
import Quotes from './Quotes.vue'
import Manifest from './Manifest.vue'
import Alert from './Alert'
import Syllabus from './Syllabus'
import Reviews from './Reviews'
import VideoGallery from './VideoGallery'
import PostsGallery from './PostsGallery'
import FormContainer from './Forms/FormContainer'
import WhatsUpForm from './Forms/WhatsUpForm'
import CourseAssignForm from './Forms/CourseAssignForm'
import Footer from './Footer'

import { reviewButton } from '@/content/reviews.json'
const pov = require('../content/point-of-view.json')
const menQuotesList = require('../content/men-quotes.json')
const manifestList = require('../content/manifest.json')
const { offsetMobile, offset } = require('@/const')
import { whatsUpHeader } from '../content/whatsup-assign.json'
import { assignHeader } from '../content/assign-course.json'

export default {
  name: 'Main',
  props: {
    assign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pointOfView: pov,
      menQuotes: menQuotesList,
      manifest: manifestList,
      assignDialog: this.assign,
      alertEnable: false,
      alertObj: {
        message: null,
        type: null,
      },
      popupDisplay: !this.assign,
      reviewButton,
      reviewsActive: false,
      whatsupAssign: false,
      whatsUpHeader,
      assignHeader,
    }
  },
  components: {
    NavBar,
    AboutUs,
    AdvicesContainer,
    Quotes,
    Manifest,
    Alert,
    Syllabus,
    Reviews,
    VideoGallery,
    PostsGallery,
    FormContainer,
    WhatsUpForm,
    CourseAssignForm,
    // Comments,
    Footer,
  },
  computed: {
    offset() {
      return this.$vuetify.breakpoint.mobile ? offsetMobile : offset
    },
  },
  methods: {
    alertSet(message, type = 'info') {
      this.alertObj = {
        message,
        type: type,
      }
      this.alertEnable = true
    },
    closeAssign() {
      this.$router.push({ path: '/' })
      this.assignDialog = false
    },
    popupAssign() {
      this.popupDisplay = false
      this.assignDialog = true
    },
    assignSent(message) {
      this.assignDialog = false
      this.alertSet(message, 'info')
    },
    whatsupReqSent(message) {
      this.whatsupAssign = false
      this.alertSet(message, 'info')
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  margin-top: 40px;
}
.combo-container {
  display: flex;
  flex-direction: row;
  flex-basis: 30%;
  column-gap: 2rem;
}

.alert-component {
  position: sticky;
  bottom: 0;
  z-index: 100;
}
.border-top {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
}

.reviews-menu {
  left: 0;
}

::v-deep .dialog-content {
  position: absolute;
  margin: 0;
  left: 0 !important;
  max-height: 95%;
  overflow: unset;
}

.reviews-btn {
  position: fixed;
  z-index: 10;
  top: 160px;
  left: 0;
  transform-origin: left bottom;
  transform: rotateY(0deg) rotate(90deg);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
@media only screen and (max-width: 700px) {
  .reviews-btn {
    top: 100px !important;
  }
}
</style>
