<template>
  <div class="main-container">
    <SimpleHeader :pageHeader="pageHeader" :pageSubHeader="comment" />
    <div class="policies-container">
      <Paragraph
        v-for="(p, index) in policies.paragraphs"
        :key="index"
        :header="p.header"
        :p-list="updateParagraph(p.content)"
      />
    </div>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import Paragraph from './Paragraph'

const policies = require('../content/policies.json')

export default {
  name: 'Policies',
  components: {
    SimpleHeader,
    Paragraph,
  },
  data() {
    return {
      policies,
    }
  },
  bgColor: {
    type: String,
    default: '#fbeee6',
  },
  computed: {
    pageHeader() {
      return this.updateText(policies.header)
    },
    comment() {
      return this.updateText(policies.headerComment)
    },
  },
  methods: {
    updateText(text) {
      return text.replaceAll('%1', policies.businessName).replaceAll('%2', policies.businessDescription)
    },
    updateParagraph(pList) {
      return pList.map(p => this.updateText(p))
    },
  },
}
</script>

<style lang="scss" scoped>
.policies-container {
  padding: 2rem 2rem;
  width: 60%;
  margin: auto;
}
@media only screen and (max-width: 700px) {
  .policies-container {
    width: 100%;
  }
}
</style>
