<template>
  <v-app-bar fixed dark :color="bgColor" :src="src" ref="nav-bar-container" class="nav-bar-container">
    <div class="bar-container">
      <img
        class="logo-image"
        :src="
          !$vuetify.breakpoint.mobile ? require('@/assets/images/tradeshield.png') : require('@/assets/images/logo.png')
        "
      />
      <v-btn depressed color="primary">
        <a :href="`tel:${callNumber}`" class="phone-link">
          <v-icon class="phone-icon">call</v-icon>
          <span v-if="!$vuetify.breakpoint.mobile">{{ phone }}</span>
        </a>
      </v-btn>
      <v-btn depressed color="primary" @click="$emit('courseAssign')">הרשמה</v-btn>
    </div>
    <NavList class="menu-nav" :goto-offset="gotoOffset" @courseAssign="$emit('courseAssign')" />
  </v-app-bar>
</template>

<script>
import NavList from './NavList'

const { bgColor } = require('@/const')
const { header, subHeader, assignButtonLabel } = require('@/content/welcome.json')
const phone = process.env.VUE_APP_PHONE
const callNumber = process.env.VUE_APP_PHONE_COUNTRY_CODE + phone.slice(1)

export default {
  name: 'NavBar',
  components: {
    NavList,
  },
  props: {
    src: {
      type: String,
    },
    gotoOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      phone,
      callNumber,
      bgColor,
      header,
      subHeader,
      assignButtonLabel,
      scrollY: 0,
      mainElementHeight: null,
    }
  },
  computed: {},
  methods: {
    openNav() {},
  },
}
</script>

<style lang="scss" scoped>
.nav-bar-container {
}
.bar-container {
  align-self: center;
  margin: auto auto auto 0;
  display: flex;
  align-items: center;
  column-gap: 0.7rem;
  direction: ltr;
}
.phone-container {
  direction: inherit;
}
.phone-link {
  color: white;
  text-decoration: none;
}
.phone-container .phone-icon {
  font-size: inherit;
}
.logo-image {
  max-height: 36px;
  max-width: 200px;
}
.menu-nav {
  align-self: center;
}
@media only screen and (max-width: 700px) {
  .phone-container {
    font-size: 1rem;
  }
  .logo-image {
    max-width: 100px;
  }
}
</style>
