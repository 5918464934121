<template>
  <BackgroundContainer class="advices-container" :bg-image="true">
    <Advice v-for="(adv, index) in advices" :key="index" v-bind="adv"> </Advice>
  </BackgroundContainer>
</template>

<script>
import BackgroundContainer from './BackgroundContainer'
import Advice from './Advice'

const advices = require('../content/advices.json')
export default {
  name: 'Advices',
  components: {
    BackgroundContainer,
    Advice,
  },
  data() {
    return {
      advices,
    }
  },
}
</script>

<style scoped>
.advices-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 0;
}
@media only screen and (max-width: 700px) {
  .advices-container {
    flex-direction: column;
    row-gap: 3rem;
  }
}
</style>
