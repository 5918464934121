<template>
  <div class="reviews-container">
    <WhatUp :header="header" :list="reviewList" @click:back="$emit('click:back')" />
  </div>
</template>

<script>
import { header, list } from '../content/reviews.json'
import WhatUp from './WhatUp'

export default {
  name: 'Reviews',
  components: { WhatUp },
  data() {
    return {
      header,
      reviewList: list,
    }
  },
}
</script>

<style lang="scss" scoped>
.reviews-container {
  top: 100px;
  z-index: 20;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
