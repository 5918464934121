import {
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  locationLabel,
  phoneNumberLabel,
  sendLabel,
  fieldRequired,
  fieldShort,
  fieldError,
  selectRequired,
} from '@/content/form-text.json'
import cityList from '@/content/city-list.json'

export default {
  data() {
    return {
      firstNameLabel,
      lastNameLabel,
      emailLabel,
      locationLabel,
      phoneNumberLabel,
      sendLabel,
      cityList,
      required: v => !!v || fieldRequired,
      selectRequired: v => !!v || selectRequired,
      textRules: v => v.length > 1 || fieldShort,
      emailRules: v => /.+@.+/.test(v) || fieldError,
      phoneRules: v => (!Number.isNaN(Number(v)) && v.length < 11) || fieldError,
      locationRequired: [v => !!v || selectRequired],
    }
  },
}
