<template>
  <v-form class="login-container" ref="formLogin">
    <v-text-field v-model="email" label="Email" :rules="emailRules" required></v-text-field>
    <v-text-field
      v-model="password"
      label="Password"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
    ></v-text-field>

    <div class="actions">
      <v-btn class="mr-4" @click="submit" color="primary"> submit </v-btn>
      <v-btn @click="reset"> clear </v-btn>
    </div>

    <Alert class="alert-component" :enable="!!errorMessage" :message="errorMessage" type="error" />
  </v-form>
</template>

<script>
import Alert from './Alert'
import requests from '@/mixins/requests'

export default {
  name: 'Login',
  mixins: [requests],
  components: {
    Alert,
  },
  data: () => ({
    showPassword: false,
    password: '',
    passwordRules: [v => !!v || 'Required'],

    email: '',
    emailRules: [v => !!v || 'Required', v => /.+@.+/.test(v) || 'This need to be a valid email'],

    errorMessage: null,
  }),

  methods: {
    validate() {
      return this.$refs.formLogin.validate()
    },
    reset() {
      this.$refs.formLogin.reset()
    },
    submit() {
      if (!this.validate()) {
        return
      }

      const data = {
        email: this.email,
        password: this.password,
      }

      this.post('/backend/login', data)
        .then(data => {
          if (typeof data !== 'object') {
            this.errorMessage = data
            return
          }
          this.$emit('update:token', data.accessToken)
          this.$router.push({ path: 'manager' })
        })
        .catch(err => {
          this.errorMessage = err
          console.error('Login error!', err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep * {
  direction: ltr;
}
.login-container {
  margin: auto;
  width: 50%;
}
.alert-component {
  margin-top: 2rem;
}
</style>
