<template>
  <div
    class="bg-container"
    :class="{ 'background-image': bgImage, 'background-image-main': mainComponent, 'background-color': true }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BackgroundContainer',
  props: {
    bgImage: {
      type: Boolean,
      default: false,
    },
    mainComponent: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-container {
  margin: 0;
  padding: 30px;
  width: 100%;
}
.background-color {
  background-color: var(--bgcolor);
}
.background-image {
  background-image: url('~@/assets/images/background2.png');
}
.background-image-main {
  background-image: url('~@/assets/images/background.png');
}
@media only screen and (max-width: 700px) {
  .background-image {
    background-image: url('~@/assets/images/background2-414.png');
    background-repeat: repeat;
  }
}
</style>
