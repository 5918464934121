<template>
  <div class="feedback-manager-container">
    <div class="table-section">
      <div class="generate-feedback">
        <v-btn class="primary" @click="generateFeedback"> generate feedback id </v-btn>
        <Alert class="alert-component" :enable="!!generatedFeedbackId" :message="generatedFeedbackId" type="success" />
      </div>

      <EditDeleteTable
        v-if="feedbackList"
        :headers="feedbackListHeaders"
        :items="feedbackList"
        :add-item-enable="false"
      />
    </div>

    <div class="feedback-aggregation-container">
      <h4 class="section-header">Feedback Result Aggregation</h4>
      <v-card class="chart-card" v-for="question in feedbackAggregation" :key="question.id">
        <v-card-title class="chart-card-header">
          <div class="question-number">{{ question.id }}</div>
          <div class="question-text">{{ question.question }}</div>
        </v-card-title>
        <FeedbackQuestionChart class="question-chart" v-bind="question" />
      </v-card>
    </div>
  </div>
</template>

<script>
import EditDeleteTable from '../EditDeleteTable'
import FeedbackQuestionChart from './FeedbackQuestionChart'
import Alert from '@/components/Alert'
import requests from '@/mixins/requests'

export default {
  name: 'FeedbackManager',
  mixins: [requests],
  props: {
    token: {
      type: String,
      require: true,
    },
  },
  components: {
    EditDeleteTable,
    FeedbackQuestionChart,
    Alert,
  },
  data() {
    return {
      header: 'Feedback Manager',
      generatedFeedbackId: null,
      feedbackList: null,
      feedbackAggregation: null,
      feedbackListHeaders: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Feedback Id',
          align: 'start',
          sortable: false,
          value: 'feedbackId',
        },
        {
          text: 'Generate Date',
          align: 'start',
          sortable: true,
          value: 'ts',
        },
        {
          text: 'Date of Done',
          align: 'start',
          sortable: true,
          value: 'updateAt',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
      ],
    }
  },
  methods: {
    async generateFeedback() {
      const data = await this.get('/backend/feedback/generate')
      this.generatedFeedbackId = data.feedbackId
      this.feedbackList = await this.getFeedbackList()
    },

    getFeedbackList() {
      return this.get('/backend/feedback/ids', { 'x-access-token': this.token })
    },

    getFeedbackAggregation() {
      return this.get('/backend/feedback/aggregation', {
        'x-access-token': this.token,
      })
    },
  },
  async mounted() {
    this.feedbackList = await this.getFeedbackList()
    this.feedbackAggregation = await this.getFeedbackAggregation()
  },
}
</script>

<style lang="scss" scoped>
::v-deep * {
  direction: ltr;
}
.feedback-manager-container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.generate-feedback {
  display: flex;
  column-gap: 2rem;
  margin-bottom: 1rem;
}
.feedback-aggregation-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;

  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.chart-card {
  padding: 2rem;
  flex-grow: 1;
  width: 30%;
  flex-shrink: 0;
}
.chart-card-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  text-align: start;
  column-gap: 0.5rem;
  font-size: 1rem;
}
.chart-card-header > div {
  direction: rtl;
}
.question-number:after {
  content: '.';
}
.question-text {
  flex-basis: 70%;
  flex-grow: 1;
  word-break: break-word;
}
.question-chart {
  max-width: 455px;
}
</style>
