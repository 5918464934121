<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 101.3 51"
    style="enable-background: new 0 0 101.3 51"
    xml:space="preserve"
    :height="height"
    :width="width"
  >
    <path
      class="st0"
      d="M26.1,50.6c-1.3,0-2.5-0.5-3.5-1.5L2.6,29.2c-1.9-1.9-1.9-5.1,0-7c1.9-1.9,5.1-1.9,7,0l16.4,16.4L62.6,2
	c1.9-1.9,5.1-1.9,7,0c1.9,1.9,1.9,5.1,0,7l-40,40C28.6,50.1,27.4,50.6,26.1,50.6z"
    />
    <path
      class="st0"
      d="M56,50.6c-1.3,0-2.5-0.5-3.5-1.5l-4.9-4.9c-1.9-1.9-1.9-5.1,0-7c1.9-1.9,5.1-1.9,7,0l1.4,1.4L92.5,2
	c1.9-1.9,5.1-1.9,7,0c1.9,1.9,1.9,5.1,0,7l-40,40C58.5,50.1,57.2,50.6,56,50.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoubleCheckIcon',
  props: {
    height: {
      type: String,
      default: '10px',
    },
    width: {
      type: String,
      default: '20px',
    },
  },
}
</script>

<style lang="scss" scoped>
.st0 {
  fill: #666666;
}
</style>
