<template>
  <v-dialog
    v-model="isActive"
    :fullscreen="$vuetify.breakpoint.mobile"
    transition="dialog-bottom-transition"
    @click:outside="$emit('click:close')"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" v-if="title"> {{ title }} </v-card-title>

      <slot></slot>

      <v-divider></v-divider>

      <v-card-actions class="card-actions">
        <v-btn icon @click="$emit('click:close')"> <v-icon>mdi-close</v-icon> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    isActive: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.card-actions {
  justify-content: center;
}
</style>
