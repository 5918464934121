<template>
  <header class="nav-bar-header" fixed>
    <div class="nav-header">
      <img class="nav-logo-image" src="@/assets/images/logo-dark-txt.png" />
      <h3>{{ header }}</h3>
    </div>
    <nav class="menu-nav">
      <a v-for="(navObj, index) in navList" :key="index" :class="{ active: navObj.active }" @click="selectNav(index)">
        {{ navObj.label }}
      </a>
    </nav>
  </header>
</template>

<script>
const { navList } = require('@/content/manager-nav.json')

export default {
  name: 'NavBarHeader',
  props: {
    header: {
      type: String,
      default: null,
    },
    gotoOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      navList,
    }
  },
  methods: {
    selectNav(index) {
      const aList = document.querySelectorAll('nav a')
      aList.forEach(a => {
        if (a.classList.contains('active')) {
          a.classList.remove('active')
        }
      })
      aList[index].classList.add('active')

      this.$emit('click:navSelect', this.navList[index])
    },
  },
  mounted() {
    const activeNab = this.navList.find(f => f.active)

    this.$emit('click:navSelect', activeNab)
  },
}
</script>

<style lang="scss" scoped>
.nav-bar-header {
  z-index: 10;
  background-color: var(--bgcolor) !important;
  box-shadow: 0 0 3px black;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 10px 0 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-bar-header * {
  direction: ltr;
}
.nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin: auto;
}
.nav-logo-image {
  height: 1.5rem;
}

.menu-nav {
  display: flex;
  column-gap: 1rem;
}
.menu-nav a {
  color: black;
  padding: 5px;
}
.menu-nav a:hover,
.menu-nav a.active {
  color: black;
  border-bottom: 1px solid black;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
