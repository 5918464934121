<template>
  <v-footer fixed class="footer-container" :color="bgColor">
    <div>All rights reserved</div>
    <div class="spacer" />
    <div>כל הזכויות שמורות</div>
    <div class="spacer" />
    <a class="facebook-logo-container" href="https://www.facebook.com/Trade.Shield.Passive.Income" target="_blank">
      <img class="facebook-logo" src="@/assets/facebook_icon.png" />
      <span class="facebook-text">Tradeshield on facebook</span>
    </a>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    bgColor: {
      type: String,
    },
  },
}
</script>

<style scoped>
.footer-container {
  padding: 10px 30px !important;
  font-size: 0.8rem;
  color: #999;
  border-top: 1px solid #999 !important;

  display: flex;
  flex-direction: row-reverse;
  column-gap: 1rem;
  justify-content: center;
}
.spacer:after {
  content: '|';
}
.facebook-logo-container {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 0.5rem;
}
.facebook-logo {
  height: 16px;
}
@media only screen and (max-width: 700px) {
  .facebook-text {
    display: none;
  }
}
</style>
