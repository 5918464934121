<template>
  <BackgroundContainer class="syllabus-container">
    <h2>{{ syllabusContent.header }}</h2>
    <div class="syllabus-content">
      <ul class="syllabus-list">
        <li v-for="(lesson, lessonsIndex) in syllabusContent.lessons" :key="`lesson-${lessonsIndex}`">
          <h3 class="sub-header">{{ lesson.header }}</h3>
          <ul class="topics-list">
            <li v-for="(topic, topicIndex) in lesson.topics" :key="`topic-${lessonsIndex}${topicIndex}`">
              {{ topic }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </BackgroundContainer>
</template>

<script>
import BackgroundContainer from './BackgroundContainer'
const syllabusContent = require('../content/syllabus.json')

export default {
  name: 'Syllabus',
  components: { BackgroundContainer },
  data() {
    return {
      syllabusContent,
    }
  },
}
</script>

<style lang="scss" scoped>
.syllabus-container {
  position: relative;
  text-align: right;

  h2 {
    margin-bottom: 1rem;
  }

  .syllabus-content {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    padding-bottom: 2rem;

    > div {
      flex-basis: 40%;
    }
  }

  * {
    direction: rtl;
  }

  .sub-header {
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

ul.syllabus-list {
  list-style-type: none;
  column-count: 3;

  > li {
    margin: 0 2rem 1.5rem 0;
    padding: 0.5rem 0 0.5rem 0;
    break-inside: avoid;

    h3 {
      display: block;
      padding-right: 5px;
      background-color: var(--color5);
    }
  }
}

ul.topics-list {
  margin-right: 30px;
  list-style: disc;

  li:after {
    content: '.';
  }
}
@media only screen and (max-width: 700px) {
  ul.syllabus-list {
    list-style-type: none;
    column-count: unset;

    > li {
      margin: 0;
    }
  }
}
</style>
