<template>
  <div class="table-main-container">
    <div class="header-section">
      <h2 class="header" v-if="title">{{ title }}</h2>
      <div class="add-item-container" v-if="addItemEnable">
        <v-btn color="primary" dark class="mb-2" @click="$emit('click:newItem')"> New Item </v-btn>
        <slot></slot>
      </div>
    </div>
    <v-data-table :headers="headers" :items="items" :items-per-page="10" class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="$emit('click:editItem', item)"> mdi-pencil </v-icon>
        <v-icon small @click="$emit('click:deleteItem', item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'EditDeleteTable',
  props: {
    title: {
      type: String,
      default: null,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addItemEnable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.header {
  font-size: 1.2rem;
}
.add-item-container {
  text-align: left;
  margin-top: 1rem;
}
</style>
