<template>
  <GalleryContainer :header="header" :subheader="subheader">
    <vueper-slides
      class="no-shadow slide-container"
      :visible-slides="$vuetify.breakpoint.mobile ? 1 : 3"
      :gap="3"
      slide-multiple
      align="center"
      fixedHeight="420px"
      arrowsOutside
      :bullets="false"
      fade
    >
      <vueper-slide v-for="(videoObj, index) in videoList" :key="`video_${index}`">
        <template #content>
          <VideoCard :video-obj="videoObj" @click:video="showVideo" />
        </template>
      </vueper-slide>
    </vueper-slides>
    <Dialog :isActive="dialogActive" @click:close="disableDialog">
      <iframe width="640" height="400" ref="iframe" v-bind="videoObj" />
    </Dialog>
  </GalleryContainer>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import GalleryContainer from './GalleryContainer'
import VideoCard from './VideoCard'
import Dialog from './Dialog'
import requests from '@/mixins/requests'
import utils from '@/mixins/utils'

const { header, subheader, basicYouTubeObj, videoList } = require('../content/video.json')

export default {
  name: 'VideoGallery',
  mixins: [requests, utils],
  components: {
    GalleryContainer,
    VideoCard,
    VueperSlides,
    VueperSlide,
    Dialog,
  },
  data() {
    return {
      header,
      subheader,
      basicYouTubeObj,
      videoList,
      dialogActive: false,
      videoObj: null,
      options: {
        currentPage: 0,
      },
    }
  },
  computed: {
    list() {
      return Array.isArray(this.videoList) ? this.videoList.map(m => ({ ...m, url: m.thumbnail })) : []
    },
  },
  methods: {
    showVideo(video) {
      if (this.$vuetify.breakpoint.mobile) {
        window.open(video.src, '_blank')
        return
      }
      this.dialogActive = true
      this.videoObj = video
    },
    disableDialog() {
      this.videoObj = null
      this.dialogActive = false
    },
    getVideosInfo() {
      return this.get('/backend/videos')
    },
  },
  mounted() {
    this.getVideosInfo().then(data => {
      this.videoList = data.map(this.updateVideoImageUrl)
    })
  },
}
</script>

<style scoped>
.slide-container {
  width: 90%;
  align-self: center;
}
@media only screen and (max-width: 700px) {
  .slide-container {
    width: 80%;
  }
}
</style>
