<template>
  <div class="advice-container" :style="{ backgroundColor: bgColor }">
    <div class="advice-header-container">
      <div class="advice-icon">
        <v-icon color="white"> {{ icon }} </v-icon>
      </div>
      <div class="advice-header">{{ header }}</div>
    </div>
    <div class="advice-content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: 'Advice',
  props: {
    icon: {},
    header: {
      type: String,
    },
    content: {
      type: String,
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
  },
}
</script>

<style scoped>
.advice-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4rem 0 12rem;
  gap: 16px;
  font-weight: 300;
  flex-basis: 25%;
  color: rgb(251, 238, 230);
}
.advice-header-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.advice-container:not(:last-child) {
  border-left: 1px dashed white;
}
.advice-icon .material-icons-outlined {
  padding: 15px;
  font-size: 26px;
  border-radius: 50%;
  border: 1px solid white;
}
.advice-header {
  font-size: 1.8rem;
  font-weight: 300;
}
.advice-content {
  font-size: 1rem;
  width: 70%;
  margin: 0 auto;
  text-align: right;
}
@media only screen and (max-width: 700px) {
  .advice-container {
    justify-content: right;
    padding: 0;
  }
  .advice-container {
    border-left: none !important;
    display: block;
  }
  .advice-header-container {
    flex-direction: row-reverse;
    margin-bottom: 1rem;
  }
  .advice-icon .material-icons-outlined {
    padding: 5px;
  }
  .advice-content {
    margin: 0;
    width: auto;
    display: block;
  }
}
</style>
