<template>
  <div class="WU-container" :style="{ 'max-height': maxHeight }">
    <div class="header" :style="{ backgroundColor: palette.TealGreen }">
      <v-icon color="white" @click="$emit('click:back')">arrow_back</v-icon>
      <div class="avatar-container">
        <img src="@/assets/images/logo-dark.png" />
      </div>
      <div>
        <h3>{{ header }}</h3>
        <div class="name-list">{{ namesList }}</div>
      </div>

      <v-icon class="fake-icon" color="white">more_vert</v-icon>
    </div>
    <ul>
      <li v-for="(review, i) in list" :key="`review-${i}`">
        <div
          v-for="(msg, magIndex) in review.msgList"
          :key="`review_${i}-msg_${magIndex}`"
          :class="['message-box', `message-box-${msg.replay ? 'left' : 'right'}`]"
        >
          <div class="message-arrow">
            <div class="inner"></div>
          </div>
          <div class="message-header" v-if="!msg.replay">
            <span class="name" :style="{ color: usernameColors[i % usernameColors.length] }">{{ review.name }}</span>
          </div>
          <div class="message-content" v-html="msg.msg" />
          <div class="check-container">
            <DoubleCheckIcon />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import DoubleCheckIcon from '../utils/DoubleCheckIcon'
import whatupColor from '../utils/whatsup-colors'

export default {
  name: 'WhatUp',
  components: { DoubleCheckIcon },
  props: {
    header: {
      required: true,
      type: String,
    },
    list: {
      required: true,
      type: Array,
    },
    maxHeight: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      palette: whatupColor.palette,
      usernameColors: whatupColor.usernameColors,
    }
  },
  computed: {
    namesList() {
      if (!this.list) return ''
      return this.list.map(review => review.name).join(', ')
    },
  },
}
</script>

<style lang="scss" scoped>
.WU-container {
  max-width: 400px;
  max-height: calc(100vh * 0.95);
  border-radius: 4px;
  background-image: url('~@/assets/images/whatup-background.jpg');
  background-repeat: repeat;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  overflow: scroll;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);

  .header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    vertical-align: center;
    height: 50px;
    padding: 5px;
    color: white;
    text-align: left;
    column-gap: 0.5rem;

    .avatar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 15px;
      overflow: hidden;

      img {
        width: 70%;
        height: 70%;
      }
    }

    h3 {
      font-weight: 700;
    }
    .name-list {
      font-size: 0.7rem;
    }
    .fake-icon {
      margin-left: auto;
    }
  }

  ul {
    list-style: none;
    padding: 5px;
  }

  li {
    margin-bottom: 2rem;

    .message-header {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
    }
    .message-box {
      position: relative;
      border-radius: 8px;
      margin: 10px;
      padding: 5px 10px 10px 10px;
      max-width: 80%;
      box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1);

      .message-header {
        font-weight: 500;
        font-size: 1rem;
        justify-content: flex-end;
      }
      .message-content {
        direction: rtl;
      }
    }
    .message-box-right {
      border-top-right-radius: 0;
      background-color: white;
      float: right;

      .message-arrow {
        right: -15px;
        top: 0px;

        .inner {
          left: -6px;
          top: -4px;
        }
      }
    }

    .message-box-left {
      border-top-left-radius: 0;
      background-color: #dcf8c6;
      float: left;

      .message-arrow {
        left: -15px;
        top: 0px;

        .inner {
          background-color: #dcf8c6;
          right: -10px;
          top: -4px;
        }
      }
    }

    .message-arrow {
      position: absolute;
      width: 15px;
      height: 15px;

      overflow: hidden;

      .inner {
        transform: rotate(45deg);
        background-color: white;
        width: 10px;
        height: 10px;
        position: relative;
        border-radius: 2px;
      }
    }

    .check-container {
      display: flex;
    }
  }
}
</style>
