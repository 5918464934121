module.exports = {
  bgColor: '#fbeee6',
  colorList: [
    '#f1d356',
    '#fc9e56',
    '#fc6e58',
    '#ef3163',
    '#ef1e74',
    '#ce2b8e',
    '#b82896',
    '#f1d356',
    '#fc9e56',
    '#fc6e58',
  ],
  markColor: '#ef3163',
  offsetMobile: 40,
  offset: 60,
  videoImagePath: '/static/images/video/',
}
