<template>
    <v-card class="post-card">
        <div class="message-title">{{ messageTitle }}</div>
        <div class="message-container">
            <div class="message-body">{{ messageBody }}</div>
            <a :href="facebookLink" target="_blank">{{ seeMore }}</a>
        </div>
        <div class="attachment-container" v-if="attachments">
            <a :href="attachment.unshimmed_url" target="_blank">
                <img class="post-image" :src="attachmentImage"/>
                <div class="attachment-title">{{ attachment.title ?? null}}</div>
                <div class="attachment-description">{{ attachment.description }}</div>
            </a>
        </div>
        <div class="publish-date">{{ new Date(created_time).toLocaleString('en-GB') }}</div>
    </v-card>
</template>

<script>
  import { seeMore } from '../content/post.json'

  export default {
    name: 'PostCard',
    props: {
      message: String,
      created_time: String,
      actions: Array,
      full_picture: String,
      attachments: Object,
      id: String,
    },
    data() {
      return {
        seeMore,
      }
    },
    computed: {
      messageTitle() {
        return this.message?.slice(0, this.message.indexOf('\n'))
      },
      messageBody() {
        return this.message?.slice(this.message.indexOf('\n')).trim()
      },
      attachment() {
        if (!this.attachments?.data) return {}
        return this.attachments.data.find(att => att.type === 'share' || att.type === 'photo' || att.type === 'native_templates')
      },
      attachmentImage() {
        if (this.full_picture && this.checkImageSize(this.full_picture)) return this.full_picture
        if (this.attachment?.media?.image?.src) return this.attachment.media.image.src
        if (this.attachment?.data?.[0]?.media?.image?.src) return this.attachment?.data?.[0]?.media?.image?.src
        return null
      },
      facebookLink() {
        if (!this.actions) return null
        const likeObj = this.actions.find(act => act.name === 'Like')

        return likeObj.link
      },
    },
    methods: {
      checkImageSize(src) {
        new Promise(resolve => {
          let img = new Image()
          img.src = src

          img.onload = () => {
            resolve(img.naturalHeight > 10)
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
    $bgGray: rgba(29, 33, 41, 0.1);

    .post-card {
        direction: rtl;
        text-align: right;
        font-size: 0.9rem;

        > * {
            padding: 0 15px;
        }

        .message-title {
            padding: 15px;
            font-weight: 700;
            background-color: $bgGray;
        }

        .message-container {
            margin-top: 10px;

            .message-body {
                white-space: pre-wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 14;
                -webkit-box-orient: vertical;
                max-height: 350px;
                margin-bottom: 1rem;
            }
        }

        .attachment-container {
            padding: 0;
            margin-top: 10px;
            padding-bottom: 10px;
            direction: ltr;
            text-align: left;
            background-color: $bgGray;

            a:link {
                text-decoration: none;
            }

            a {
                color: black;

                > div {
                    padding: 0 15px;
                }
            }

            .post-image {
                padding: 0;
                width: 100%;
                height: 100%;
                max-height: 273px;
            }

            .attachment-title {
                font-weight: 700;
                font-size: 1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .attachment-description {
                color: #90949c;
                font-size: 0.9rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .publish-date {
            padding: 5px;
            font-size: 0.8rem;
            color: #90949c;
        }
    }
</style>
