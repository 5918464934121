<template>
  <RadioGroup
    :option-list="optionList"
    :group-label="groupLabel"
    :reverse="reverse"
    :inherit-rule-messages="rulesMessage"
    column
    @input="handleChange"
  >
    <template v-slot:label="{ option }">
      <div class="option-info-container">
        <div class="option-label">{{ option.label }}</div>
        <div class="option-content">
          <div class="option-info">{{ option.info }}</div>
          <span class="option-extra-info" v-if="option.extraInfo">{{ option.extraInfo }}</span>
        </div>
<!--        <div class="option-price">-->
<!--          <div class="current-price">-->
<!--            <span class="price" :class="{ 'price-mark': option.priceMark }">{{ option.price }}</span>-->
<!--            <span v-if="typeof option.price === 'number'" class="option-price-currency">{{ option.currency }}</span>-->
<!--          </div>-->

<!--          <div class="option-old-price" v-if="option.oldPrice">-->
<!--            <span class="price">{{ option.oldPrice }}</span>-->
<!--            <span class="option-price-currency">{{ option.currency }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="on-sale" v-if="option.onSale"><img src="@/assets/images/sale-icon.png" /></div>-->
      </div>
    </template>
  </RadioGroup>
</template>

<script>
import { VTextField } from 'vuetify/lib'
import RadioGroup from './RadioGroup'

export default {
  name: 'RadioGroupCourse',
  extends: VTextField,
  components: { RadioGroup },
  props: {
    groupLabel: {
      type: String,
    },
    optionList: {
      type: Array,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
  },
  computed: {
    rulesMessage() {
      return this.messagesToDisplay
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.option-info-container {
  position: relative;
  direction: rtl;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  width: 90%;

  > * {
    flex-basis: 23%;
  }
  .option-content {
    flex-basis: 45%;
    flex-grow: 2;
  }

  .option-label {
    text-align: right;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .option-content {
    text-align: right;
  }
  .option-info {
    font-weight: 500;
    font-size: 0.9rem;
  }
  .option-extra-info {
    font-weight: 300;
    font-size: 0.9rem;
  }
  .option-price {
    .current-price {
      position: relative;
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
    }
    .option-old-price .price {
      text-decoration: line-through;
    }
    .option-price-currency {
      font-size: 0.8rem;
      padding: 0 0.3rem;
    }
    .price-mark {
      color: var(--success);
      font-weight: 700;
    }
  }
  .on-sale {
    position: absolute;
    top: 0px;
    left: -64px;

    img {
      width: 70%;
    }
  }
}
@media only screen and (max-width: 700px) {
  .option-info-container {
    gap: 0.8rem;

    .option-label {
      font-size: 1rem;
    }

    .on-sale {
      left: -40px;
      top: 43px;
    }
  }
}
</style>
