const palette = {
  Green: '#4AB969',
  TealGreen: '#075E54',
  CheckMarkBlue: '#34B7F1',
  TealGray: '#495B64',
  ChetBubble: '#DCF8C6',
  LightGreen: '#25D366',
  Red: '#EA1C43',
  LightGray: '#F2F2F2',
}

const usernameColors = [
  '#98376d',
  '#e6b44d',
  '#72bf70',
  '#808fa1',
  '#94a750',
  '#e07f71',
  '#bca14f',
  '#c09fca',
  '#74c3d8',
  '#632c78',
  '#6f2737',
  '#27363f',
]

export default {
  palette,
  usernameColors,
}
