<template>
  <v-form @submit="submitForm" class="my-4" ref="form">
    <v-text-field v-model="firstName" :rules="[required, textRules]" :label="firstNameLabel" reverse />
    <v-text-field v-model="lastName" :rules="[textRules]" :label="lastNameLabel" reverse />
    <v-text-field v-model="email" :rules="[required, emailRules]" :label="emailLabel" reverse />
    <v-text-field v-model="phone" :rules="[required, phoneRules]" :label="phoneNumberLabel" reverse />
    <v-autocomplete
      reverse
      class="autocomplete"
      v-model="cityModel"
      :items="cityList"
      :loading="false"
      hide-no-data
      hide-selected
      item-text="nameHeb"
      item-value="townId"
      :label="locationLabel"
      :rules="locationRequired"
      placeholder=""
      prepend-icon="location_city"
      return-object
    >
    </v-autocomplete>
    <RadioGroup
      :option-list="sourceList"
      :group-label="sourceLabel"
      v-model="source"
      :rules="[selectRequired]"
      reverse
    />
    <RadioGroupCourse
      ref="group"
      :group-label="courseOptionsLabel"
      :option-list="courseOptions"
      v-model="courseType"
      :rules="[selectRequired]"
      reverse
    />
    <v-textarea class="question-field" :label="textAreaLabel" v-model="question" :rows="2" reverse />
    <Alert
      class="alert-component"
      :enable="alertEnable"
      :message="alertObj.message"
      :type="alertObj.type"
      :timeoutEnable="true"
      @close="alertEnable = false"
    />
    <v-btn type="submit" color="primary">{{ sendLabel }}</v-btn>
  </v-form>
</template>

<script>
import Alert from '../Alert'
import RadioGroup from './RadioGroup'
import RadioGroupCourse from './RadioGroupCourse'
import formHelpers from '../../mixins/form-rules.js'
import {
  sourceList,
  sourceLabel,
  courseOptionsLabel,
  courseOptions,
  textAreaLabel,
  assigned,
  assignError,
} from '@/content/assign-course.json'
import requests from '../../mixins/requests'

export default {
  name: 'AssignForm',
  mixins: [formHelpers, requests],
  components: { Alert, RadioGroup, RadioGroupCourse },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      cityModel: '',
      source: '',
      courseType: 'online-single',
      question: '',
      errorMessage: null,
      alertObj: {
        message: '',
        type: 'info',
      },
      alertEnable: false,
      sourceList,
      sourceLabel,
      courseOptionsLabel,
      courseOptions,
      textAreaLabel,
    }
  },
  methods: {
    setAlert(message, type = 'info') {
      this.alertObj = {
        message,
        type: type,
      }
      this.alertEnable = true
    },
    validateForm() {
      return this.$refs.form.validate()
    },

    async submitForm(event) {
      event.preventDefault()
      if (!this.validateForm()) {
        return
      }

      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phone,
        location: `${this.cityModel.nameHeb}, ${this.cityModel.country}`,
        source: this.source,
        type: this.courseType,
        message: this.question,
      }
      const response = await this.post('/backend/message', data)

      if (typeof response !== 'object') {
        this.errorMessage = response
        this.setAlert(assignError, 'error')
        return
      }
      this.$emit('assign:sent', assigned)
    },
  },
}
</script>

<style lang="scss" scoped>
.question-field {
  direction: rtl;
}
</style>
