<template>
  <div class="main-container">
    <NavBarHeader header="Manager" bg-color="#fbeee6" @click:navSelect="loadTab($event)" />
    <Alert
      class="alert-component offset"
      :enable="!!alertMessage"
      :message="alertMessage"
      :type="alertType"
      :timeoutEnable="true"
      @close="alertMessage = null"
    />
    <FeedbackManager ref="feedback" class="disabled offset" />
    <VideosTable ref="videos" class="disabled offset" />
  </div>
</template>

<script>
import NavBarHeader from '@/components/NavBar/NavBarHeader'
import FeedbackManager from '@/components/Manager/Feedback/index'
import VideosTable from '@/components/Manager/VideosTable/index'
import Alert from '@/components/Alert'
import requests from '@/mixins/requests'

export default {
  name: 'ManagerApp',
  mixins: [requests],
  components: {
    NavBarHeader,
    FeedbackManager,
    VideosTable,
    Alert,
  },
  data() {
    return {
      alertMessage: null,
      alertType: 'error',
    }
  },
  methods: {
    loadTab(navObj) {
      Object.keys(this.$refs).forEach(key => {
        if (key === navObj.id) {
          this.$refs[key].$el.classList.remove('disabled')
          return
        }
        this.$refs[key].$el.classList.add('disabled')
      })
    },
    authCheck() {
      return this.get('/backend/manager')
    },
  },
  mounted() {
    this.authCheck()
      .then(() => {
        this.alertMessage = 'manager authorized'
        this.alertType = 'success'
      })
      .catch(err => {
        this.alertMessage = err
        this.alertType = 'error'
      })
  },
}
</script>

<style lang="scss" scoped>
::v-deep.main-container * {
  direction: ltr;
}
.offset {
  margin-top: 65px;
  padding: 2rem;
}
.alert-component {
  padding: 1rem;
}
.disabled {
  display: none;
}
</style>
