<template>
  <v-card class="video-card-container" max-width="360" @click="$emit('click:video', videoObjGen())">
    <v-img height="250" :src="videoImage"></v-img>
    <v-card-title class="card-title-container">
      <v-icon class="pl-1">ondemand_video</v-icon>
      {{ videoObj.title }}
    </v-card-title>
    <v-card-text>
      <div class="statistics-container">
        <div>{{ videoObj.statistics.viewCount }} views</div>
        <div>{{ videoObj.statistics.likeCount }} <v-icon small :right="true">thumb_up</v-icon></div>
      </div>
      <div class="video-content" v-if="videoObj.content">{{ videoObj.content }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import requests from '@/mixins/requests'
const { basicYouTubeObj } = require('../content/video.json')

export default {
  name: 'VideoCard',
  mixins: [requests],
  props: {
    videoObj: {
      type: Object,
      required: true,
    },
  },
  computed: {
    videoImage() {
      return this.addServerUrl(this.videoObj.thumbnail)
    },
  },
  methods: {
    videoObjGen() {
      return {
        src: this.videoObj.src,
        ...basicYouTubeObj,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.video-card-container {
  width: 100%;
  direction: rtl;
}
.statistics-container {
  direction: ltr;
  display: flex;
  column-gap: 1rem;
  justify-content: right;
}
.statistics-container > div {
  direction: inherit;
  display: flex;
  align-items: center;
}
.video-content {
  text-align: right;
}

@media only screen and (max-width: 700px) {
  .card-title-container {
    font-size: 1rem !important;
  }
}
</style>
