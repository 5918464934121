<template>
  <BackgroundContainer class="men-quote-container">
    <div class="men-quotes" v-for="man in men" :key="man">
      <div class="man-icon"><img :src="require(`@/assets/images/men/${menQuotes[man].icon}`)" /></div>
      <div class="name-header">{{ menQuotes[man].name }}</div>
      <div class="quotes-container">
        <div class="quote" v-for="(quote, qindex) in menQuotes[man].quotes" :key="`${man}${qindex}`">
          <v-icon class="material-icons-outlined">format_quote</v-icon>
          <div class="quote-text">{{ quote }}</div>
        </div>
      </div>
    </div>
  </BackgroundContainer>
</template>

<script>
import BackgroundContainer from './BackgroundContainer'

export default {
  name: 'MenQuotes',
  components: {
    BackgroundContainer,
  },
  props: {
    menQuotes: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    men() {
      return Object.keys(this.menQuotes)
    },
  },
}
</script>

<style scoped>
.men-quote-container {
  display: flex;
  flex-direction: row;
  direction: rtl;
  text-align: right;
  padding: 32px;
  justify-content: space-between;
  column-gap: 1rem;
}
.men-quotes {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  row-gap: 1rem;
}
.man-icon > img {
  width: 25%;
  border-radius: 50%;
}
.name-header {
  font-weight: 800;
}
.quotes-container {
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
}
.quote {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}
.quote:nth-child(even) {
  background-color: rgba(200, 200, 200, 0.2);
}
@media only screen and (max-width: 700px) {
  .men-quote-container {
    flex-direction: column;
    row-gap: 3.5rem;
  }
  .man-icon > img {
    width: 15%;
  }
}
</style>
