<template>
  <v-app>
    <router-view @update:token="updateToken" :token="token" />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      token: null,
      keypress: null,
    }
  },
  methods: {
    updateToken(token) {
      this.token = token
      console.log('token:', token)
    },
  },
  created() {
    const loginString = 'login'
    const self = this
    let stringPressed = ''

    window.addEventListener('keyup', function (e) {
      stringPressed = (stringPressed + e.key).slice(-loginString.length)
      if (stringPressed === loginString) {
        self.stringPressed = ''
        self.$router.push({ path: 'login' })
      }
    })
  },
}
</script>

<style type="scss">
:root {
  --bgcolor: #fbeee6;
  --mark: #ef3163;
  --color5: #f1d356;
  --success: #4caf50;
  --gradiant: linear-gradient(to bottom right, #f1d356, #fc9e56, #fc6e58, #ef3163, #ef1e74, #ce2b8e);
}

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;

  transition-property: all;
  transition-duration: 0.3s;
}

h1 {
  font-weight: 700;
  font-size: 1.4rem;
  display: inline-flex;
}
h2 {
  font-weight: 500;
  font-size: 1.5rem;
  display: inline-flex;
}
h3 {
  font-weight: 500;
  font-size: 1rem;
  display: inline-flex;
}
h4 {
  font-weight: 300;
  font-size: 1rem;
  display: inline-flex;
}
h5 {
  font-weight: 700;
  font-size: 1rem;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 1500px;
  position: relative;
  margin: auto;
  background-color: var(--bgcolor);
  padding-bottom: 3rem;
}
</style>
