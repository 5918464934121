<template>
  <div class="feedback-question-chart-container">
    <HorizontalBarChart class="bar-chart" :chartData="dataCollection" :options="chartOptions" />
  </div>
</template>

<script>
import HorizontalBarChart from '@/utils/HorizontalBarChart'

const { colorList } = require('@/const.js')

export default {
  name: 'FeedbackQuestionChart',
  components: {
    HorizontalBarChart,
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
    question: {
      type: String,
      require: true,
    },
    answers: {
      type: Object,
      require: true,
      //object in array
      // {
      //   id: 'INTEGER',
      //   answer: 'TEXT',
      //   count: integer,
      // },
    },
  },
  data() {
    return {
      colors: colorList || [],
      chartOptions: {
        indexAxis: 'y',
        responsive: true,
        legend: {
          display: false,
          position: 'bottom',
        },
        title: {
          display: false,
          text: `${this.id}. ${this.question}`,
          fontSize: 14,
          fontFamily: "'Open Sans', sans-serif",
          fontStyle: 'normal',
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Score',
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              scaleLabel: {
                display: true,
                labelString: 'Vote Count',
              },
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
          ],
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function () {
              return ''
            },
            label: function (tooltipItem, chart) {
              const qObj = chart.chartData[tooltipItem.label]
              const viewDataList = [`${qObj.count} person/s choose answer: ${qObj.answer ? qObj.answer : qObj.id}`]

              return viewDataList
            },
          },
        },
      },
    }
  },
  computed: {
    dataCollection() {
      return this.convertData()
    },
  },
  methods: {
    convertData() {
      const chartDate = {
        labels: [],
        datasets: [
          {
            axis: 'y',
            label: null,
            data: [],
            fill: false,
            backgroundColor: this.colors,
            borderColor: this.colors,
            borderWidth: 1,
            barPercentage: 0.5,
            barThickness: 14,
          },
        ],
        chartData: this.answers,
      }

      const keys = Object.keys(this.answers)
      return keys.reduce((acc, answerId) => {
        acc.labels.push(answerId)
        acc.datasets[0].data.push(this.answers[answerId].count)

        return acc
      }, chartDate)
    },
  },
}
</script>

<style lang="scss" scoped></style>
