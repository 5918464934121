<template>
  <BackgroundContainer class="video-gallery-container">
    <div class="header">{{ header }}</div>
    <div class="sub-header" v-if="subheader">{{ subheader }}</div>

    <slot name="default" />
  </BackgroundContainer>
</template>

<script>
import BackgroundContainer from './BackgroundContainer'

export default {
  name: 'GalleryContainer',
  components: {
    BackgroundContainer,
  },
  props: {
    header: {
      type: String,
      default: null,
    },
    subheader: {
      type: String,
      default: null,
    },
    itemList: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.video-gallery-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.header {
  font-size: 1.5rem;
  text-align: right;
}
</style>
