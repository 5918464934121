<template>
  <v-radio-group column v-model="value" @change="$emit('update', { value, inputValue })" :rules="rules">
    <template slot="label">
      <div class="radio-label">{{ question }}</div>
    </template>
    <div class="options-container">
      <v-radio class="options-container-radio" v-for="option in answers" :key="option.id" :value="option.id">
        <template slot="label">
          <div class="option-label">
            <div>{{ option.answer ? option.answer : option.id }}</div>
            <v-text-field
              v-if="option.input"
              v-model="inputValue"
              reverse
              class="extra-input"
              hide-details="auto"
              @change="$emit('update', { value, inputValue })"
            ></v-text-field>
          </div>
        </template>
      </v-radio>
    </div>
  </v-radio-group>
</template>

<script>
export default {
  name: 'question',
  props: {
    question: {
      type: String,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      inputValue: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.radio-label {
  text-align: right;
  font-size: 1.2rem;
  padding-bottom: 1rem;
}
.options-container {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  row-gap: 1rem;
}
.option-label {
  padding-right: 0.6rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: baseline;
  column-gap: 1rem;
}
.extra-input {
  width: 400px;
}
@media only screen and (max-width: 700px) {
  .extra-input {
    width: 250px;
  }
}
</style>
