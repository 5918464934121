import { render, staticRenderFns } from "./Syllabus.vue?vue&type=template&id=820fdb14&scoped=true&"
import script from "./Syllabus.vue?vue&type=script&lang=js&"
export * from "./Syllabus.vue?vue&type=script&lang=js&"
import style0 from "./Syllabus.vue?vue&type=style&index=0&id=820fdb14&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "820fdb14",
  null
  
)

export default component.exports