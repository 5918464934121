<template>
  <div class="feedback-id-container">
    <div class="feedback-row feedback-id-action">
      <v-text-field
        ref="FeedbackIdInput"
        :label="feedback.feedbackLabel"
        v-model="feedbackId"
        :rules="feedbackIdRules"
        reverse
        hide-details="auto"
        :disabled="!feedbackIdButton"
        class="feedback-id-input"
      ></v-text-field>
      <v-btn class="feedback-id-button" @click="checkFeedbackId" color="primary" :disabled="!feedbackIdButton">
        {{ feedback.feedbackIdButton }}
      </v-btn>
    </div>
    <div class="feedback-id-alert feedback-row">
      <Alert
        class="feedback-id-error-message"
        :enable="feedbackErrorMessage"
        :message="feedbackErrorMessage"
        type="error"
      />
    </div>
  </div>
</template>

<script>
import Alert from '../Alert'
import requests from '@/mixins/requests'

const feedback = require('../../content/feedback.json')

export default {
  name: 'feedbackId',
  mixins: [requests],
  components: {
    Alert,
  },
  data() {
    return {
      feedback,
      feedbackId: '',
      feedbackIdButton: true,
      feedbackIdRules: [
        v => !!v || this.feedback.feedbackIdRequired,
        v => (v && v.length > 50) || this.feedback.feedbackIdError,
      ],
      feedbackErrorMessage: null,
    }
  },
  methods: {
    checkFeedbackId() {
      this.feedbackErrorMessage = null
      if (!this.$refs.FeedbackIdInput.valid) return

      return this.get(`/backend/feedback/id-check/${this.feedbackId}`)
        .then(async () => {
          this.showFeedback = true
          this.feedbackIdButton = false
          this.$emit('update:feedbackId', this.feedbackId)
        })
        .catch(async response => {
          if (response.status === 401) {
            this.feedbackErrorMessage = this.feedback.feedbackIdError401
            return
          }
          if (response.status === 403) {
            this.feedbackErrorMessage = this.feedback.feedbackIdError403
            return
          }

          this.feedbackErrorMessage = await response.text()
        })
    },
  },
}
</script>

<style scoped>
.feedback-id-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  row-gap: 1rem;
}
.feedback-row {
  display: flex;
  column-gap: 2rem;
  align-items: baseline;
}
.feedback-id-error-message {
  flex-basis: 100%;
}
@media only screen and (max-width: 700px) {
  .feedback-id-container {
    width: 100%;
  }
  .feedback-id-action {
    flex-direction: column;
    row-gap: 1rem;
  }
  .feedback-id-input,
  .feedback-id-button {
    width: 100%;
  }
}
</style>
