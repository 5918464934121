<template>
  <section class="radio-group" :class="{ reverse: reverse, 'validation-error': !!message }">
    <label class="select-source-label" v-if="groupLabel">{{ groupLabel }}:</label>
    <div class="select-group" :class="{ 'group-column': column }">
      <label v-for="option in optionList" :key="option.value">
        <input type="radio" :name="groupName" :value="option.value" @click="handleChange(option.value)" />
        <slot name="label" v-bind:option="option">
          {{ option.name }}
        </slot>
      </label>
    </div>
    <div class="message" :class="{ active: !!message }">
      {{ message }}
    </div>
  </section>
</template>

<script>
import { VTextField } from 'vuetify/lib'

export default {
  name: 'RadioGroup',
  extends: VTextField,
  props: {
    groupLabel: {
      type: String,
    },
    groupName: {
      type: String,
      default: () => `radio-group${Math.floor(Math.random() * 1000000000)}`,
    },
    optionList: {
      type: Array,
    },
    column: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    inheritRuleMessages: {
      type: Array,
    },
  },
  computed: {
    message() {
      return this.inheritRuleMessages?.[0] || this.messagesToDisplay[0]
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
$error-color: #ff5252;

* {
  transition-property: all;
  transition-duration: 0.3s;
}

.reverse {
  direction: rtl;
  * {
    direction: rtl;
  }
}
.radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  margin: 20px 0;

  .select-group.group-column {
    flex-direction: column;
    > * {
      align-items: flex-start;
      padding: 20px 0;
    }
    input {
      margin-top: 6px;
    }
  }

  .select-group {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: right;

    > * {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 0.6rem;
      cursor: pointer;
    }
  }

  .message {
    line-height: 12px;
    font-size: 0.8em;
    color: $error-color;
    text-align: end;
    height: 0.8em;
    width: 100%;
    transform: translateY(0px) scale(1);
  }
  .message.active {
    transform: translateY(25px) scale(1);
  }
}
.validation-error {
  border-color: $error-color;
}
</style>
