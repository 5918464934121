<template>
  <BackgroundContainer class="about-container" :bg-image="true" :main-component="true">
    <h2>{{ mainHeader }}</h2>
    <div class="layout-container__main">
      <div class="layout-container__sub">
        <p>{{ main }}</p>

        <h5>{{ services.header }}</h5>
        <ul class="service-list">
          <li v-for="(service, serviceIndex) in services.serviceList" :key="serviceIndex">
            <p>{{ service }}</p>
          </li>
        </ul>
        <div class="action-container">
          <v-btn elevation="2" class="button mx-2" color="primary" @click="$emit('click:courseAssign')">
            {{ assignCourseLabel }}
          </v-btn>
          <v-btn elevation="2" class="button mx-2" color="success" @click="$emit('click:courseWhatsup')">
            {{ assignWhatsupLabel }} <strong>{{ freeLabel }}</strong>
          </v-btn>
        </div>
      </div>
      <div class="layout-container__sub">
        <h5>{{ whatsUpHeader }}</h5>
        <ul class="whats-up-list">
          <li v-for="(item, itemIndex) in whatsUpGroup" :key="`item${itemIndex}`">
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>
    </div>
  </BackgroundContainer>
</template>

<script>
import BackgroundContainer from './BackgroundContainer'

import {
  mainHeader,
  main,
  services,
  whatsUpHeader,
  whatsUpGroup,
  assignCourseLabel,
  assignWhatsupLabel,
  freeLabel,
} from '../content/about.json'

export default {
  name: 'AboutUs',
  components: {
    BackgroundContainer,
  },
  data() {
    return {
      mainHeader,
      main,
      services,
      whatsUpHeader,
      whatsUpGroup,
      assignCourseLabel,
      assignWhatsupLabel,
      freeLabel,
    }
  },
}
</script>

<style lang="scss" scoped>
.about-container {
  margin-top: 10px;
  direction: rtl;
  display: flex;
  flex-direction: column;
  text-align: right;
  color: white;

  h2,
  h5 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  .layout-container__main {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;

    > div {
      flex-basis: 50%;
    }

    ul.service-list {
      list-style: decimal;
      margin-right: 20px;
    }

    ul.whats-up-list {
      margin-right: 30px;
    }
  }

  strong {
    padding-right: 5px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 700px) {
  .layout-container__main {
    flex-direction: column !important;
    row-gap: 1rem;
  }
  .action-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      width: 80%;
      margin: 1rem auto;
    }
  }
}
</style>
