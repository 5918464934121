<template>
  <div class="small">
    <div class="header">
      {{ header }}
      <div class="bold-header">
        <label>{{ boldHeader }}</label>
        <div class="bold-header-content">{{ totalAvg }} %</div>
      </div>
    </div>
    <bar-chart class="bar-chart" :chartData="dataCollection" :options="chartOptions"></bar-chart>
    <div class="remark">
      <span class="material-icons-outlined"> {{ remarkIcon }} </span>
      {{ remark }}
    </div>
  </div>
</template>

<script>
import BarChart from '../utils/BarChart'

const { header, boldHeader, remark, remarkIcon, dataList, dataListMap } = require('@/content/chart-data.json')
const { colorList } = require('@/const.js')

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      colors: colorList || [],
      chartData: dataList || [],
      header,
      boldHeader,
      remark,
      remarkIcon,
      chartOptions: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
        },
        title: {
          display: false,
          text: header,
          fontSize: 20,
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'ROR -Rate Of Return (תשואות)',
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
            },
          ],
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          displayColors: false,
          titleAlign: 'right',
          bodyAlign: 'right',
          callbacks: {
            title: (tooltipItem, chart) => {
              return chart.chartdata[tooltipItem[0].index]?.symbolHeb
                ? `(${tooltipItem[0].label}) ${chart.chartdata[tooltipItem[0].index].symbolHeb}`
                : tooltipItem[0].label
            },
            label: function (tooltipItem, chart) {
              const data = chart.chartdata[tooltipItem.index]
              const viewDataList = [
                `${dataListMap.enterDate}: ${data.enterDate}`,
                `${dataListMap.enterPrice}: ${data.enterPrice}`,
                `${dataListMap.sellDate}: ${data.sellDate}`,
                `${dataListMap.sellPrice}: ${data.sellPrice}`,
                `${dataListMap.actROR}: ${data.actROR}%`,
                `${dataListMap.expROR}: ${data.expROR}%`,
              ]
              return viewDataList
            },
          },
        },
      },
    }
  },
  computed: {
    dataCollection() {
      return this.convertData()
    },

    totalAvg() {
      return (
        Math.ceil(
          (this.chartData.reduce((avg, dataObj) => {
            avg += dataObj.actROR
            return avg
          }, 0) /
            this.chartData.length) *
            100
        ) / 100
      )
    },
  },
  methods: {
    convertToDate(dateString) {
      // dd.mm.yyyy
      const dateList = dateString.split('.')
      const dataObj = new Date(dateList[2], dateList[1] - 1, dateList[0])
      return dataObj.valueOf()
    },

    convertData() {
      const datasets = this.chartData.reduce(
        (acc, stck) => {
          acc.datasets[0].data.push(stck.expROR)
          acc.datasets[1].data.push(stck.actROR)

          acc.labels.push(stck.symbol)
          return acc
        },
        {
          labels: [],
          datasets: [
            {
              backgroundColor: 'transparent',
              borderColor: this.colors,
              borderWidth: 1,
              barPercentage: 0.5,
              barThickness: 14,
              label: 'expected',
              data: [],
            },
            {
              backgroundColor: this.colors,
              pointBorderWidth: 1,
              barPercentage: 0.5,
              barThickness: 14,
              label: 'actual',
              data: [],
            },
          ],
          chartdata: this.chartData,
        }
      )
      return datasets
    },
  },
}
</script>

<style lang="scss" scoped>
.small {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.header {
  font-size: 0.95rem;
  font-weight: 700;
}
.bold-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.6rem;
  direction: rtl;

  border: 4px double black;
  width: 50%;
  margin: 15px auto auto;
}
.bold-header-content {
  font-size: 1.6rem;
  direction: ltr;
}
.bar-chart {
  margin-right: 30px;
}
.remark {
  flex-basis: 100%;
  font-size: 0.8rem;
  align-items: center;

  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  column-gap: 0.5rem;
}
@media only screen and (max-width: 700px) {
  .small {
    max-width: 100%;
    row-gap: 0;
  }
  .bold-header {
    padding: 0 7px;
    margin-bottom: 10px;
    width: 80%;
  }
}
</style>
