const baseHeaders = { 'Access-Control-Allow-Origin': '*' }
const headers = { 'Content-Type': 'application/json', ...baseHeaders }

function addServerUrl(url) {
  if (process.env.NODE_ENV === 'production') return url
  if (url.startsWith('http')) return url

  return process.env?.VUE_APP_SERVERURL ? process.env.VUE_APP_SERVERURL + url : url
}

function parseRes(response) {
  if ([200, 201, 202, 203].includes(response.status)) {
    return response.json()
  }
  if ([400, 401, 403].includes(response.status)) {
    return response.text()
  }
  throw new Error(`${response.status}`)
}

function get(url, headerExt = {}) {
  return fetch(addServerUrl(url), {
    method: 'GET',
    headers: { ...headerExt, ...headers },
  })
    .then(parseRes)
    .catch(error => {
      console.error('Get req was an error!', error)
    })
}

function post(url, jsonData = {}) {
  return fetch(addServerUrl(url), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(jsonData),
  })
    .then(parseRes)
    .catch(error => {
      console.error('Post req error!', error)
    })
}

function postForm(url, formData) {
  return fetch(addServerUrl(url), {
    method: 'POST',
    headers: baseHeaders,
    body: formData,
  })
    .then(parseRes)
    .catch(error => {
      console.error('Post req error!', error)
    })
}

function del(url) {
  return fetch(addServerUrl(url), {
    method: 'DELETE',
    headers: headers,
  })
    .then(parseRes)
    .catch(error => {
      console.error('Post req error!', error)
    })
}
export default {
  methods: {
    addServerUrl,
    get,
    post,
    postForm,
    del,
  },
}
