<template>
  <v-dialog
    width="600px"
    content-class="form-dialog"
    v-model="enableDialog"
    attach="#app"
    :fullscreen="fullscreenMode || $vuetify.breakpoint.mobile"
    transition="dialog-bottom-transition"
    @click:outside="$emit('click:close')"
  >
    <v-card rounded="0" :color="bgColor">
      <v-card-title class="dialog-header" :style="{ backgroundColor: bgColor }">
        <slot name="header">{{ title }}</slot>
        <v-btn class="close-button" icon @click="$emit('click:close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot name="default" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormContainer',
  props: {
    enableDialog: {
      type: Boolean,
      default: false,
    },
    fullscreenMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '#ffffff',
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-header {
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  direction: rtl;
  align-items: center;
  padding: 11px !important;
  background-image: url('~@/assets/images/background.png');
  animation-direction: normal;
  animation-name: move;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  animation-fill-mode: both;

  .close-button {
    margin-left: 0;
    margin-right: auto;
    color: white;
  }
}
@media only screen and (max-width: 700px) {
  .dialog-header {
    font-size: 1rem !important;
  }
}

@keyframes move {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 90% 0%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>
