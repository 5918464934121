<template>
  <div class="header" :style="{ backgroundColor: bgColor }">
    <v-btn class="close-button" icon @click="$router.push({ path: '/' })">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="header-main">
      {{ pageHeader }}
    </div>
    <div class="comment">{{ pageSubHeader }}</div>
  </div>
</template>
<script>
export default {
  name: 'simpleHeader',
  props: {
    pageHeader: {
      type: String,
      required: true,
    },
    pageSubHeader: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      default: '#fbeee6',
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 7px 15px !important;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: ltr;
  box-shadow: 0 0 5px black;
}
.header-main {
  flex-basis: 100%;
  direction: rtl;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
}
.logo-image {
  width: 12%;
}
.comment {
  font-weight: 300;
  font-size: 1rem;
}
</style>
