import Vue from 'vue'
import Router from 'vue-router'
import Main from '../components/Main'
import Login from '../components/Login'
import ManagerApp from '../components/Manager'
import FeedbackFrom from '../components/FeedbackForm'
import PoliciesAndTerms from '../components/PoliciesAndTerms'
import Syllabus from '../components/Syllabus'

Vue.use(Router)

export default new Router({
  routes: [
    { name: 'FeedbackFrom', path: '/feedback', component: FeedbackFrom },
    { name: 'PoliciesAndTerms', path: '/policies-and-terms', component: PoliciesAndTerms },
    { name: 'Syllabus', path: '/syllabus', component: Syllabus },
    { name: 'Login', path: '/login', component: Login, props: { navEna: true } },
    { name: 'Manager', path: '/manager', component: ManagerApp },
    { name: 'Assign', path: '/assign', component: Main, props: { navEna: true, assign: true } },
    { name: 'Main', path: '/*', component: Main, props: { navEna: true } },
  ],
  scrollBehavior: () => ({
    y: 0,
    behavior: 'smooth',
  }),
  mode: 'history',
})
