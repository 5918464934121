<template>
  <v-form @submit="submitForm" class="my-4" ref="form">
    <v-text-field v-model="firstName" :rules="[required, textRules]" :label="firstNameLabel" reverse required />
    <v-text-field v-model="lastName" :rules="[textRules]" :label="lastNameLabel" reverse required />
    <v-text-field v-model="email" :rules="[required, emailRules]" :label="emailLabel" reverse required />
    <v-text-field v-model="phone" :rules="[required, phoneRules]" :label="phoneNumberLabel" reverse required />
    <v-autocomplete
      reverse
      required
      class="autocomplete"
      v-model="location"
      :items="cityList"
      :loading="false"
      hide-no-data
      hide-selected
      item-text="nameHeb"
      item-value="townId"
      :label="locationLabel"
      :rules="locationRequired"
      placeholder=""
      prepend-icon="location_city"
      return-object
    >
    </v-autocomplete>
    <div class="disclosure-container my-2">
      {{ disclosure }}
    </div>
    <Alert
      class="alert-component"
      :enable="alertEnable"
      :message="alertObj.message"
      :type="alertObj.type"
      :timeoutEnable="true"
      @close="alertEnable = false"
    />
    <v-btn type="submit" color="primary">{{ sendLabel }}</v-btn>
  </v-form>
</template>

<script>
import Alert from '../Alert'

import formHelpers from '../../mixins/form-rules.js'
import { disclosure, assigned, assignError } from '@/content/whatsup-assign.json'
import requests from '../../mixins/requests'

export default {
  name: 'WhatsUpForm',
  mixins: [formHelpers, requests],
  components: { Alert },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      location: '',
      disclosure,
      errorMessage: null,
      alertObj: {
        message: '',
        type: 'info',
      },
      alertEnable: false,
    }
  },
  methods: {
    alertSet(message, type = 'info') {
      this.alertObj = {
        message,
        type: type,
      }
      this.alertEnable = true
    },

    validateForm() {
      return this.$refs.form.validate()
    },

    async submitForm(event) {
      event.preventDefault()
      if (!this.validateForm()) {
        return
      }

      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        location: this.location,
      }
      const response = await this.post('/backend/whatsup-group', data)

      if (typeof response !== 'object') {
        this.errorMessage = response
        this.alertSet(assignError, 'error')
        return
      }
      this.$emit('assign:sent', assigned)
    },
  },
}
</script>

<style lang="scss" scoped>
.disclosure-container {
  direction: rtl;
  text-align: right;
}
</style>
