<template>
    <GalleryContainer :header="header" class="post-galley-container">
        <vueper-slides
                class="no-shadow slide-container"
                :visible-slides="$vuetify.breakpoint.mobile ? 1 : 3"
                :gap="3"
                slide-multiple
                align="center"
                fixedHeight="800px"
                arrowsOutside
                :bullets="false"
                fade
        >
            <vueper-slide v-for="post in postListItems" :key="post.id">
                <template #content>
                    <FacebookPostCard v-bind="post"/>
                </template>
            </vueper-slide>
        </vueper-slides>
    </GalleryContainer>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'

  import GalleryContainer from './GalleryContainer'
  import FacebookPostCard from './FacebookPostCard'
  import requests from '@/mixins/requests'
  import utils from '../mixins/utils'

  const { header } = require('../content/post.json')
  const { desktopSize, mobileSize, showAllList } = require('../content/post.json')

  export default {
    name: 'PostsGallery',
    mixins: [requests, utils],
    components: {
      GalleryContainer,
      VueperSlides,
      VueperSlide,
      FacebookPostCard,
    },
    data() {
      return {
        header,
        showAllList,
        postList: [],
      }
    },
    computed: {
      postSize() {
        return this.$vuetify.breakpoint.mobile ? mobileSize : desktopSize
      },
      maxHeight() {
        return Math.max(...this.postList.map(post => post.size.height)) + 4
      },
      postListItems() {
        return this.postList.filter(f => !!f.message_tags) || []
      },
    },
    methods: {
      getPostsInfo() {
        return this.get('/backend/posts/facebook/posts?limit=30')
      },
    },
    mounted() {
      document.cookie = 'SameSite=None'
      this.getPostsInfo().then(data => {
        this.postList = data.data
      })
    },
  }
</script>

<style scoped>
    .slide-container {
        width: 90%;
        align-self: center;
    }

    @media only screen and (max-width: 700px) {
        .slide-container {
            width: 90%;
        }

        ::v-deep .vueperslides__arrows > button {
            top: 15em;
        }
    }
</style>
