<template>
  <div class="main-container">
    <SimpleHeader :pageHeader="feedback.header" :pageSubHeader="feedback.subHeader" />
    <v-form
      class="form"
      ref="form"
      :disabled="!formEnable"
      v-model="formValid"
      lazy-validation
      :style="{ backgroundColor: bgColor }"
      transition="dialog-bottom-transition"
    >
      <div class="content">
        <FeedbackId @update:feedbackId="updateFeedbackId" />

        <div class="form-questions" v-if="showFeedback">
          <Question
            v-for="question in feedbackQuestions"
            :key="question.id"
            :rules="questionRules"
            v-bind="question"
            @update="updateQuestion(question.id, $event)"
          />
          <div class="actions">
            <Alert class="error-message" :enable="!!alertMessage" :message="alertMessage" :type="alertType" />
            <v-btn class="action-button" @click="sendForm" color="primary" :disabled="!formEnable">
              {{ feedback.sendLabel }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import FeedbackId from './FeedbackId'
import Question from './Question'
import Alert from '../Alert'
import requests from '@/mixins/requests'

const feedback = require('../../content/feedback.json')

export default {
  name: 'feedbackFrom',
  mixins: [requests],
  components: {
    SimpleHeader,
    FeedbackId,
    Question,
    Alert,
  },
  props: {
    bgColor: {
      type: String,
      default: '#fbeee6',
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formEnable: true,
      showFeedback: false,
      feedbackId: null,
      formValid: false,
      feedback,
      feedbackQuestions: {},
      questionList: {},
      questionRules: [v => !!v || this.feedback.questionsMissing],
      alertMessage: null,
      alertType: 'error',
    }
  },
  methods: {
    updateQuestion(questionId, { value, inputValue }) {
      this.alertMessage = null
      this.questionList[questionId] = {
        questionId: questionId,
        answer: value,
      }
      if (inputValue) this.questionList[questionId].input = inputValue
    },

    validate() {
      const valid = this.$refs.form.validate()
      this.alertMessage = valid ? null : this.feedback.missingIssue
      return valid
    },

    reset() {
      this.$refs.form.reset()
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async sendForm() {
      this.alertMessage = null

      if (!this.validate()) {
        return
      }

      const data = { questions: this.questionList, feedbackId: this.feedbackId }

      return this.post('/backend/feedback', data)
        .then(() => {
          this.formEnable = false
          this.alertType = 'success'
          this.alertMessage = this.feedback.successMessage
        })
        .catch(error => {
          console.error('There was an error!', error)
          //response.status === 401
          this.alertMessage = this.feedback.feedbackIdError403
        })
    },
    updateFeedbackId(id) {
      this.feedbackId = id
      this.showFeedback = true
    },

    getFeedbackQuestions() {
      return this.get('/backend/feedback/questions')
    },
  },

  async mounted() {
    this.feedbackQuestions = await this.getFeedbackQuestions()
  },
}
</script>

<style lang="scss" scoped>
.form {
  direction: rtl;
  padding-bottom: 1rem;
}
.form-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 7px 15px !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: ltr;
  box-shadow: 0 0 5px black;
}
.form-header-main {
  flex-basis: 100%;
  direction: rtl;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
}
.logo-image {
  width: 12%;
}
.header {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}
.comment {
  font-weight: 300;
  font-size: 1rem;
}
.content {
  padding: 1.2rem;
}

.action-button {
  min-width: 150px !important;
  margin: 0 1rem;
}
.error-message {
  margin-top: 1rem;
}
@media only screen and (max-width: 700px) {
  .options-container {
    flex-direction: column;
  }
  .options-container-radio {
    flex-basis: 100%;
  }
  .logo-image {
    width: 30%;
    height: auto;
  }
  .action-button {
    margin: auto;
    width: 90%;
  }
}
</style>
