<template>
  <div class="paragraph-container">
    <div class="paragraph-header-container">
      <v-avatar v-if="avatar" :size="`${avatarSize}px`">
        <img :src="require(`@/assets/images/${avatar}`)" alt="Avatar" />
      </v-avatar>
      <div class="header-container">
        <h2 :class="{ 'main-header': isMain }" v-if="header">{{ header }}</h2>
        <div class="subHeader" v-if="subHeader">{{ subHeader }}</div>
      </div>
    </div>
    <p class="paragraph-list-container" v-for="(p, index) in pList" :key="index" v-html="p" />
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    avatarSize: {
      type: Number,
      default: 100,
    },
    avatar: {
      type: String,
    },
    header: {
      type: String,
    },
    isMain: {
      type: Boolean,
      default: false,
    },
    subHeader: {
      type: String,
    },
    pList: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.paragraph-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  direction: rtl;
  margin: 2rem 0;
}
.paragraph-header-container {
  display: flex;
  column-gap: 2rem;
}
.header-container {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.main-header {
  font-size: 3rem;
}
.subHeader {
  font-weight: 700;
  margin-top: -10px;
}
.paragraph-list-container {
  direction: rtl;
  text-align: right;
  font-weight: 300;
}
.paragraph-list-container:first-child {
  padding-top: 3rem;
}
</style>
