import Vue from 'vue'
import MaterialIcons from '@dbetka/vue-material-icons'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import vuetify from './plugins/vuetify'
import router from './router'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(VueGtag, {
  config: { id: 'AW-10803000603' },
})
Vue.use(MaterialIcons)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
