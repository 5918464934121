<template>
  <EditDeleteTable
    :title="title"
    :headers="headers"
    :items="rowList"
    @click:editItem="editItem($event)"
    @click:deleteItem="deleteItem($event)"
    @click:newItem="editDialog = true"
  >
    <v-dialog v-model="editDialog" width="500px" v-if="editDialog">
      <AddDialog
        :item.sync="editDialogItem"
        @click:done="doneEdit($event)"
        @upload:file="uploadImage($event)"
        @remove:file="editDialogItem.thumbnail = null"
      />
    </v-dialog>
  </EditDeleteTable>
</template>

<script>
import EditDeleteTable from '../EditDeleteTable'
import AddDialog from './AddDialog'
import requests from '@/mixins/requests'

const { videoEdit } = require('@/content/video.json')

export default {
  name: 'EditTable',
  mixins: [requests],
  components: {
    EditDeleteTable,
    AddDialog,
  },
  data() {
    return {
      title: 'Videos List Table',
      headers: videoEdit.headers,
      addItemObj: false,
      editDialog: false,
      editDialogItem: {},
      videos: [],
    }
  },
  computed: {
    rowList() {
      return this.addItemObj ? [...this.videos, this.emptyObj] : this.videos
    },

    emptyObj() {
      return this.headers.reduce((acc, headerObj) => {
        acc[headerObj.value] = null
        return acc
      }, {})
    },
  },
  methods: {
    editItem(item) {
      this.editDialogItem = Object.keys(this.emptyObj).reduce((acc, k) => {
        acc[k] = item[k]
        return acc
      }, {})
      this.editDialog = true
    },

    async deleteItem(item) {
      await this.deleteItemReq(item.id)
      this.items = await this.videoReq()
    },

    newItem() {
      this.editDialogItem = { ...this.emptyObj }
      this.editDialog = true
    },

    updateItem(item) {
      return this.post('/backend/videos', item)
    },

    async doneEdit(update) {
      this.editDialog = false
      if (update) {
        await this.updateItem(this.editDialogItem)
        this.videos = await this.videoReq()
      }
      this.editDialogItem = {}
    },

    uploadImage(fileName) {
      this.editDialogItem.thumbnail = fileName
    },

    deleteItemReq(id) {
      return this.del(`/backend/video/${id}`)
    },

    videoReq() {
      return this.get('/backend/videos')
    },
  },
  mounted() {
    this.videoReq().then(data => {
      this.videos = data
    })
  },
}
</script>

<style lang="scss" scoped></style>
