<template>
  <BackgroundContainer class="manifest-container">
    <div class="column">
      <Paragraph v-for="paragraph in paragraphs" v-bind="paragraph" :key="paragraph.id" :id="paragraph.id"> </Paragraph>
    </div>
    <div class="column chart-column">
      <ror-chart />
    </div>
  </BackgroundContainer>
</template>

<script>
import BackgroundContainer from './BackgroundContainer'
import RorChart from './RorChart'
import Paragraph from './Paragraph'

const { paragraphs } = require('../content/manifest.json')

export default {
  name: 'Manifest',
  components: {
    BackgroundContainer,
    RorChart,
    Paragraph,
  },
  props: {
    manifestHeader: {
      type: String,
    },
    manifestSub: {
      type: String,
    },
    manifestList: {
      type: Array,
    },
  },
  data() {
    return {
      paragraphs,
    }
  },
}
</script>

<style scoped>
.manifest-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  column-gap: 6rem;
}
.manifest-container > div {
  flex-basis: 50%;
}
.chart-column {
  position: sticky;
  top: 140px;
  align-self: flex-start;

  display: flex;
  justify-content: center;
}
.header-container > img {
  width: 20%;
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .manifest-container {
    flex-direction: column-reverse;
  }
  .column {
    position: relative;
    top: 0;
  }
  .manifest-container > div {
    max-width: 100%;
  }
}
</style>
