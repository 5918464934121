<template>
  <nav class="nav-container">
    <v-menu offset-y content-class="menu">
      <template v-slot:activator="{ on, attrs }">
        <span>{{ menu }}</span>
        <v-btn class="ma-0" small icon fab v-bind="attrs" v-on="on"><v-icon>menu</v-icon></v-btn>
      </template>
      <v-list :color="bgColor" dense class="list-container">
        <v-list-item v-for="(navObj, index) in navList" :key="index" class="list-item">
          <a class="nav-link" @click="gotoId(index)">
            <v-icon :class="{ 'sale-color': navObj.event }" v-if="navObj.icon">
              {{ navObj.icon }}
            </v-icon>
            <span v-if="navObj.label" :class="{ strong: navObj.event, withIcon: navObj.icon }">{{ navObj.label }}</span>
          </a>
        </v-list-item>
      </v-list>
    </v-menu>
  </nav>
</template>

<script>
const { menu, navList, gotoList } = require('../../content/nav.json')
import { bgColor } from '@/const'

export default {
  name: 'NavList',
  props: {
    gotoOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      bgColor,
      menu,
      navList,
      gotoList,
      selectedItem: 0,
      menuEna: false,
      isMobile: this.$vuetify.breakpoint.mobile,
    }
  },
  computed: {
    showMenu() {
      return this.menuEna
    },
  },
  methods: {
    openNav() {
      this.$refs['menu-list'].style.display = 'block'
    },
    gotoId(index) {
      const navObj = navList[index]

      this.menuEna = false
      if (navObj.event) {
        this.$emit(navObj.event)
        return
      }
      if (navObj.url) {
        this.$router.push({ path: navObj.url })
        // const routeData = this.$router.resolve({ path: navObj.url })
        // window.open(routeData.href, '_blank')
        return
      }
      this.$vuetify.goTo(`#${navObj.id}`, { offset: this.gotoOffset })
    },
  },
  mounted() {
    const currentRouteName = this.$router.currentRoute.name
    this.selectedItem = navList.findIndex(f => f.name === currentRouteName)
  },
}
</script>

<style scoped>
.nav-link {
  display: flex;
  align-items: center;
}
.nav-link * {
  color: black;
}
.sale-color {
  color: var(--mark);
}
.strong {
  font-weight: 700;
}
.withIcon {
  padding-right: 4px;
}
.list-container {
  padding: 0;
}
.list-item {
  direction: rtl;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 0.8rem;
}
</style>
