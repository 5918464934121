<template>
  <v-card class="edit-item">
    <v-card-title>
      <span class="text-h5">Add/Edit Item</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="item.id" label="Id"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="item.title" label="Title"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="item.content" label="Content"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="10">
                <v-text-field v-model="item.thumbnail" label="Image" disabled></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn class="mx-2" fab small color="error" @click="$emit('remove:file')">
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-file-input
              accept="image/*"
              label="File input"
              v-model="imageFile"
              :show-size="1000"
              :error-messages="fileErrorMessage"
            ></v-file-input>
            <v-btn color="blue darken-1" text @click="uploadImage" :loading="loadingFile" :disabled="loadingFile">
              upload
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close()"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { embedUrl } from '@/content/video.json'
import requests from '../../../mixins/requests'

export default {
  name: 'EditVideo',
  mixins: [requests],
  data: () => ({
    dialog: false,
    imageFile: null,
    fileErrorMessage: null,
    loadingFile: false,
  }),
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    uploadImage() {
      this.loadingFile = true
      let formData = new FormData()
      formData.append('file', this.imageFile)

      return this.postForm('/backend/upload-video-image', formData)
        .then(data => {
          if (typeof data === 'object') {
            //this.editDialogItem.thumbnail = data.fileName
            this.$emit('upload:file', data.fileName)
            return
          }
          this.fileErrorMessage = data
        })
        .catch(err => {
          this.fileErrorMessage = err
        })
        .finally(() => {
          this.loadingFile = false
        })
    },
    save() {
      this.item.src = `${embedUrl}${this.item.id}`
      this.close(true)
    },
    close(update = false) {
      this.$emit('click:done', update)
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-item * {
  direction: ltr !important;
}
</style>
